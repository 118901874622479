import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllSiteVisitReport, getProjectById } from '../../Redux/actions';
import React, { useEffect, useState } from 'react';
import { Can } from '../../Context/Can';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tooltip, IconButton, CircularProgress, Button } from '@mui/material';
import { Grid, TableRow, TableContainer, TableCell, TableHead, Table, TableBody } from '@mui/material';
import { Edit } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ImagePlaceholder from '../../Images/image-placeholder.png'
import { updateParams } from '../../utils';
import BackArrow from '../../Images/BackArrow.png';
import Slider from "react-slick";

export default function SiteVisitReportList({ dPids }) {
    const { id, client_id, facilityId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [siteVisitReportList, setSiteVisitReportList] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [showLoader, setShowLoader] = useState(true);


    const [selectedImage, setSelectedImage] = useState([]);
    const [openImageDialog, setOpenImageDialog] = useState(false);


    const [permissionNotFound, setPermissionNotFound] = useState(false)
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [itemsPerPage, setItemsPerPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);

    const [disablePrev, setDisablePrev] = useState(true);
    const [disableNext, setDisableNext] = useState(true);
    const [imageLoading, setImageLoading] = useState({});

    useEffect(() => {
        fetchData()
    }, [id, client_id, dPids])

    const fetchData = (previousPage) => {
        setShowLoader(true);
        const form = {
            ...existingParams,
            page: previousPage? previousPage : existingParams?.page,
        }
        setSiteVisitReportList([])
        if( dPids?.length > 0 || id ) {
            dispatch(getAllSiteVisitReport(client_id, id ? id : dPids, form)).then((res) => {
                if( id && !res.data.success && !res.data.permissionFound && res?.data?.message !== 'Project not found!') {
                    setPermissionNotFound(true);
                }
                if (!res.data.data) {
                    setShowLoader(false);
                    return;
                }
                
                const allSiteVisitReports = res.data.data;
                setSiteVisitReportList(allSiteVisitReports);
                setItemsPerPage(res?.data?.itemsPerPage)
                setCurrentPage(res?.data?.currentPage)
                setShowLoader(false);
            });

            if ( id ){
                dispatch(getProjectById(client_id, id)).then(res =>{
                    if (!res.data.projectData) {
                        return
                    }
                    setProjectData(res.data.projectData)
                })
            }
        }
    }

    useEffect(() => {
        if (existingParams?.page > 1) {
            fetchData();
        } else if (!existingParams?.page) {
            updateQueryParams({ page: 1 });
        }
    }, [queryParams]);


    const siteVisitColumn = [
        'SI No',
        ...(facilityId ? ['Project'] : []),
        'Site Visit Type',
        'Description',
        'Attached Images',
        'Visited Date',
        'Report Time',
        'Engineer Incharge',
        'Ticket Number',
        'Action',
    ];

    const handleExpandedViewClick = (selectedItem) => {
        facilityId ? navigate(`/client/${client_id}/digitalisation/${facilityId}/site-visit-report/${selectedItem.pid}/view/${selectedItem.id}`) : navigate(`/client/${client_id}/projects/site-visit-report/${id}/view/${selectedItem.id}`, { state: { selectedItem } });
    }

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenImageDialog(true);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
        setSelectedImage(null);
    };

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleForwardButtonClick = () => {
        const currentPage = parseInt(existingParams?.page) || 1;
        const nextPage = currentPage + 1;
        updateQueryParams({ page: nextPage });
    };

    const handleBackwardButtonClick = () => {
        const currentPage = parseInt(existingParams?.page) || 1;
        const previousPage = currentPage > 1 ? currentPage - 1 : 1;
        updateQueryParams({ page: previousPage });
        if( previousPage === 1 ) {
            fetchData(previousPage);
        }
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <img
            src={BackArrow}
            alt="back"
            className={className}
            style={{ ...style, right:0, transform: 'rotate(180deg)' , display: disableNext ? 'none' : 'block', marginRight: '10px'}}
            onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
            src={BackArrow}
            alt="back"
            className={className}
            style={{ ...style, left:0, display: disablePrev ? 'none' : 'block', marginLeft: '10px'}}
            onClick={onClick}
          />
        );
      }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        centerMode: true,
        centerPadding: '0',
        beforeChange: (current, next) => {
            setDisablePrev(next === 0);
            setDisableNext(next === selectedImage?.length - 1);
        },
        onInit: () => {
            setDisableNext(selectedImage?.length <= 1);
        }
      };
    let siteVisitReportTable;
    if (showLoader) {
        siteVisitReportTable = (
            <TableRow>
                <TableCell colSpan={siteVisitColumn.length} style={{ textAlign: 'center', paddingTop: '50px' }}>
                    <CircularProgress />
                </TableCell>
            </TableRow>
        );


    } else if (siteVisitReportList?.length > 0) {
        siteVisitReportTable = (siteVisitReportList?.sort((a, b) => b.id - a.id)?.map((item, index) => {
            const reportDescription = item?.descriptionNew;
            const descriptionList = reportDescription.split('<br>').filter(str => str.trim() !== '');
            

            const tickets = item?.ticketNumber;
            const ticketsArr = tickets?.split(',') || [];
            const ticketNumber = ticketsArr.filter(item => item !== null && !item.startsWith('null'));
            
            return <TableRow key={index}
                onMouseEnter={() => {
                    setHoveredRowId(item?.id);
                }}
                onMouseLeave={() => {
                    setHoveredRowId(null);
                }}>
                <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                {facilityId && (<TableCell>{item?.project?.projname}</TableCell>)}
                <TableCell>{item?.siteVisitType}</TableCell>
                <TableCell>
                <ul style={{ maxHeight: '100px', overflowY: 'auto' }}>
                    {descriptionList.map((description, idx) => (
                        <div className='custom-scroll'>
                            <li key={idx}>{description}</li>
                        </div>
                    ))}
                </ul>
            </TableCell>
                <TableCell>
                    <div key={index}>
                        {item?.dynamicFieldsData?.map((val, idx) => {
                            if (val.images?.length === 0) {
                                return
                            }
                            return (
                                <>
                                    <img
                                        src={ImagePlaceholder}
                                        alt="Attachment"
                                        style={{ cursor: 'pointer', maxWidth: '20px', marginRight: '5px' }}
                                        onClick={() => {
                                            handleImageClick(val?.images)
                                            setDisablePrev(true)
                                        }}
                                    />
                                </>)
                        })}
                    </div>
                </TableCell>

                <TableCell>{moment(item?.visitedDate).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{moment(item?.reportTime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                <TableCell>{item?.engineerVisiting}</TableCell>
                <TableCell>{ticketNumber.join(',')}</TableCell>


                <TableCell>
                    <Grid style={{ display: 'flex' }}>
                        <Tooltip title="View">
                            <IconButton aria-label="view"
                                style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px', marginRight: '10px' }}
                                onClick={(e) => {
                                    localStorage.setItem('siteVisitQueryParams', queryParams);
                                    handleExpandedViewClick(item)
                                }}
                            >
                                <VisibilityIcon className="cursor-pointer" />
                            </IconButton>
                        </Tooltip>
                        <Can do="manage" on="EditSiteVisitReport">
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit"
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                onClick={() => {
                                    localStorage.setItem('siteVisitQueryParams', queryParams);
                                    facilityId ? navigate(`/client/${client_id}/digitalisation/${facilityId}/site-visit-report/${item?.pid}/edit/${item?.id}`) : navigate(`/client/${client_id}/projects/site-visit-report/${item?.pid}/edit/${item?.id}`)
                                }}
                                >
                                    <Edit className="cursor-pointer"
                                        style={{ color: 'black' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Can>
                    </Grid>

                </TableCell>
            </TableRow>
        }))
    } else {
        siteVisitReportTable = (
            <TableRow>
                <TableCell colSpan={12} align="center">
                    <h5 className='w3-text-grey'>
                        No Data Found
                    </h5>
                </TableCell>
            </TableRow>
        )
    }
    if(permissionNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    You don't have view or manage permission
                </h5>
            </Grid>
        </Grid>
    }
    return <>
        <Grid container sx={{ mt: 2 }}>
            <Grid container item justifyContent={'space-between'}>
                {!facilityId && (<Grid item sx={{ mb: 2 }} xs={12}>
                    <h4 className='card-title'>Site Visit {projectData?.projname && `- ${projectData?.projname}`}</h4>
                </Grid>)}
                <Can do="manage" on="AddSiteVisitReport">
                    <Grid sx={{ mb: 2 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => 
                                facilityId ? navigate(`/client/${client_id}/digitalisation/${facilityId}/site-visit-report/create`) : navigate(`/client/${client_id}/projects/site-visit-report/${id}/create`)
                            }
                        >
                            Add Site Visit Report
                        </Button>
                    </Grid>
                </Can>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {siteVisitColumn?.map(item => (
                                    <TableCell className='font-weight-600 font-size-14 ' >{item}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {siteVisitReportTable}
                        </TableBody>
                    </Table>


                </TableContainer>
            </Grid>
            <Grid container justifyContent={'center'} className='w3-padding-16'>
                <Button
                    onClick={handleBackwardButtonClick}
                    variant="outlined"
                    style={{ width: '110px', marginRight: '4px' }}
                    startIcon={<NavigateBeforeIcon />}
                    disabled={parseInt(existingParams?.page) === 1}
                >
                    Previous
                </Button>
                <Button
                    onClick={handleForwardButtonClick}
                    variant="outlined"
                    style={{ width: '110px', justifyContent: 'flex-end', marginLeft: '4px' }}
                    endIcon={<NavigateNextIcon />}
                    disabled={siteVisitReportList?.length < itemsPerPage || siteVisitReportList?.length === 0 || !siteVisitReportList || siteVisitReportList?.error}
                >
                    Next
                </Button>
            </Grid>
                <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
                <DialogTitle><IconButton
                        aria-label="close"
                        onClick={handleCloseImageDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'gray',
                            zIndex: 100
                        }}
                    >
                        <CloseIcon />
                    </IconButton></DialogTitle>
                <DialogContent>
                    <div>
                        <div className="slider-container"   style={{overflow: 'hidden', position: 'relative' }}>
                            <Slider {...settings}>
                                {selectedImage?.map((item, idx) => (
                                    <div key={idx} className='slider-component'>
                                        {imageLoading[idx] && (
                                            <CircularProgress
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    width:'40px',
                                                    height: '40px',
                                                    zIndex: '100'
                                                }}
                                            />
                                        )}
                                        <img src={item?.signedUrl}
                                            alt="Attachment"
                                            className='slider-image'
                                            onLoad={() => setImageLoading(prev => ({ ...prev, [idx]: false }))}
                                            onError={() => setImageLoading(prev => ({ ...prev, [idx]: false }))}
                                            onLoadStart={() => setImageLoading(prev => ({ ...prev, [idx]: true }))}
                                        />
                                    </div>
                                )
                                )}
                            </Slider>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </Grid>

    </>
}