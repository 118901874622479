
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMeterCalibration, setStoreData, cloneCalibrationReport, getMidsByFacilityId } from '../../Redux/actions';
import { Grid, Card, TableRow, TableContainer, TableCell, TableHead, Table, TableBody, Dialog, DialogTitle, DialogContent, Select, DialogActions, MenuItem } from '@mui/material';
import Paper from '@mui/material/Paper';
import moment from 'moment';

import { Can } from '../../Context/Can';

import { Tooltip, IconButton, CircularProgress, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import AlertDialog from '../Common/AlertDialog';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSearchParams } from 'react-router-dom';

import { updateParams } from '../../utils';
import BackArrow from '../../Images/BackArrow.png';
import Slider from "react-slick";
import ImagePlaceholder from '../../Images/image-placeholder.png'
import CloseIcon from '@mui/icons-material/Close';

export default function CalibrationList({ dMid, dFacilityId, dPids }) {
    const { id: mid, client_id } = useParams();
    const id = mid ? mid : dMid
    const [calibrationData, setCalibrationData] = useState();
    const [showLoader, setShowLoader] = useState(true);
    const [hoveredRowId, setHoveredRowId] = useState(null);

    const [alertMessage, setAlertMessage] = useState();
    const [msgType, setMsgType] = useState("success");

    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [itemsPerPage, setItemsPerPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [disablePrev, setDisablePrev] = useState(true);
    const [disableNext, setDisableNext] = useState(true);
    const [imageLoading, setImageLoading] = useState({});
    const [selectedImage, setSelectedImage] = useState([]);
    const [openImageDialog, setOpenImageDialog] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fetchData();
        if (existingParams?.page) {
            return
        }
        updateQueryParams({ page: 1 })
    }, [queryParams, dPids])

    const fetchData = () => {
        setShowLoader(true);
        setCalibrationData(null)
        const form = {
            ...existingParams,
            page: existingParams?.page,
        }
        
        if (dPids){
            form.dPids = dPids
        }

        if ( (dPids?.length > 0 || id) && existingParams?.page ) {
            dispatch(getMeterCalibration(client_id, dFacilityId ? dFacilityId : id, form)).then(resp => {
                if (!resp?.data?.data) {
                    setShowLoader(false);
                    return
                }
                setCalibrationData(resp.data.data)
                setItemsPerPage(resp?.data?.itemsPerPage)
                setCurrentPage(resp?.data?.currentPage)
                setShowLoader(false);
            })
        } 
    }

    const calibrationListColumnNames = [
        'Sl. No.',
        'Meter Id',
        'Meter Name',
        'Device Id',
        'Date of installation',
        'Latest Calibrated Date',
        'Due Date',
        'Overdue Days',
        'Attached Images',
        'Lifetime',
        'Working Status',
        '',
    ];


    useEffect(() => {
        fetchData();

    }, [id, client_id])

    const handleExpandedViewClick = (selectedItem) => {
        dFacilityId ? navigate(`/client/${client_id}/digitalisation/${dFacilityId}/calibration-report/view/${selectedItem.id}`) : navigate(`/client/${client_id}/meters/calibration-report/view/${selectedItem.id}`, { state: { selectedItem } });
        dispatch(setStoreData('selectedCalibrationReport', selectedItem))
    }

    const cloneCalibrationReportAction = (reportId) => {
        dispatch(cloneCalibrationReport(client_id, reportId)).then(resp => {
            if (!resp?.data?.success) {
                setAlertMessage(resp.data.message)
                setMsgType("fail")
                return
            }
            setAlertMessage(resp.data.message)
            setMsgType("success")
            fetchData();

        })
    }



    const handleDialogClose = () => {
        setAlertMessage(null)
        fetchData();
    }

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleForwardButtonClick = () => {
        const currentPage = parseInt(existingParams?.page) || 1;
        const nextPage = currentPage + 1;
        updateQueryParams({ page: nextPage });
    };

    const handleBackwardButtonClick = () => {
        const currentPage = parseInt(existingParams?.page) || 1;
        const previousPage = currentPage > 1 ? currentPage - 1 : 1;
        updateQueryParams({ page: previousPage });
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenImageDialog(true);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
        setSelectedImage(null);
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <img
            src={BackArrow}
            alt="back"
            className={className}
            style={{ ...style, right:0, transform: 'rotate(180deg)' , display: disableNext ? 'none' : 'block', marginRight: '10px'}}
            onClick={onClick}
          />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
            src={BackArrow}
            alt="back"
            className={className}
            style={{ ...style, left:0, display: disablePrev ? 'none' : 'block', marginLeft: '10px'}}
            onClick={onClick}
          />
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        centerMode: true,
        centerPadding: '0',
        beforeChange: (current, next) => {
            setDisablePrev(next === 0);
            setDisableNext(next === selectedImage?.length - 1);
        },
        onInit: () => {
            setDisableNext(selectedImage?.length <= 1);
        }
    };

    let calibrationTable;
    if (showLoader) {
        calibrationTable = (
            <TableRow>
                <TableCell colSpan={calibrationListColumnNames.length} style={{ textAlign: 'center', paddingTop: '50px' }}>
                    <CircularProgress />
                </TableCell>
            </TableRow>
        );
    } else if (calibrationData?.length > 0) {
        calibrationTable = (calibrationData?.sort((a, b) => b.id - a.id)?.map((item, index) => {
            const currentDate = new Date();

            const calibrationDueDate = new Date(item.calibration_due_date);
            const timeDifference = calibrationDueDate ? currentDate - calibrationDueDate : null;
            const daysDifference = timeDifference ? Math.floor(timeDifference / (24 * 60 * 60 * 1000)) : null;
            const overDueDays = (daysDifference > 1) ? daysDifference : 'NA';

            const lastWorkingDate = new Date(item?.last_working_date);
            const installationDate = new Date(item.installation_date);
            const installationLifetimeDifference = (installationDate && lastWorkingDate) ? lastWorkingDate - installationDate : null;
            const lifetimeDaysDifference = installationLifetimeDifference ? Math.floor(installationLifetimeDifference / (24 * 60 * 60 * 1000)) : null;
            const lifetimeDays = (lifetimeDaysDifference > 0 && item?.workingStatus === "no") ? lifetimeDaysDifference : 'NA';

            return <TableRow key={index}
                onMouseEnter={() => {
                    setHoveredRowId(item?.id);
                }}
                onMouseLeave={() => {
                    setHoveredRowId(null);
                }}>
                <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                <TableCell>{item?.meter?.meterid}</TableCell>
                <TableCell className='two-line-ellipsis'>{item?.meter?.metername}</TableCell>
                <TableCell>{item?.sensor_id}</TableCell>
                <TableCell>{moment(installationDate).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{moment(item?.latest_calibration_date).format('YYYY-MM-DD')}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{moment(item?.calibration_due_date).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{overDueDays}</TableCell>
                <TableCell>
                    <div key={index}>
                        {item?.dynamicFieldsData?.map((val, idx) => {
                            if (val.images?.length === 0) {
                                return
                            }
                            return (
                                <>
                                    <img
                                        src={ImagePlaceholder}
                                        alt="Attachment"
                                        style={{ cursor: 'pointer', maxWidth: '20px', marginRight: '5px' }}
                                        onClick={() => {
                                            handleImageClick(val?.images)
                                            setDisablePrev(true)
                                        }}
                                    />
                                </>)
                        })}
                    </div>
                </TableCell>
                <TableCell>{lifetimeDays}</TableCell>
                <TableCell className='text-capitalize'>{item?.workingStatus}</TableCell>
                <TableCell>
                    <Grid style={{ display: 'flex' }}>
                        <Can do="manage" on="EditCalibrationReport">
                        <Tooltip title="Clone">
                            <IconButton aria-label="view"
                                style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                onClick={() => cloneCalibrationReportAction(item?.id)}
                            >
                                <ContentCopyIcon className="cursor-pointer" />
                            </IconButton>
                        </Tooltip>
                        </Can>
                        <Tooltip title="View">
                            <IconButton aria-label="view"
                                style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                onClick={() => {
                                    localStorage.setItem('calibrationQueryParams', queryParams);
                                    handleExpandedViewClick(item)
                                }}
                            >
                                <VisibilityIcon className="cursor-pointer" />
                            </IconButton>
                        </Tooltip>
                        <Can do="manage" on="EditCalibrationReport">
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit"
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                    onClick={() => {
                                        localStorage.setItem('calibrationQueryParams', queryParams);
                                        dFacilityId ? navigate(`/client/${client_id}/digitalisation/${dFacilityId}/calibration-report/edit/${item.id}`) : navigate(`/client/${client_id}/meters/calibration-report/edit/${item.id}`)
                                    }}
                                >
                                    <Edit className="cursor-pointer"
                                        style={{ color: 'black' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Can>
                    </Grid>

                </TableCell>
            </TableRow>
        }))
    } else {
        calibrationTable = (
            <TableRow>
                <TableCell colSpan={12} align="center">
                    <h5 className='w3-text-grey'>
                        No Data Found
                    </h5>
                </TableCell>
            </TableRow>
        )
    }
    return (
        <Grid container sx={{ mt: 2 }}>
            <Grid container item justifyContent={'space-between'}>
                <Can do="manage" on="AddCalibrationReport">
                    <Grid sx={{ mb: 2 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => dFacilityId ? navigate(`/client/${client_id}/digitalisation/${dFacilityId}/calibration-report/create`) : navigate(`/client/${client_id}/meters/calibration-report/${id}/create`)}
                        >
                            Add Calibration Report
                        </Button>
                    </Grid>
                </Can>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {calibrationListColumnNames?.map(item => (
                                    <TableCell className='font-weight-600 font-size-14 ' >{item}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {calibrationTable}
                        </TableBody>
                    </Table>


                </TableContainer>
            </Grid>
            <Grid container justifyContent={'center'} className='w3-padding-16'>
                <Button
                    onClick={handleBackwardButtonClick}
                    variant="outlined"
                    style={{ width: '110px', marginRight: '4px' }}
                    startIcon={<NavigateBeforeIcon />}
                    disabled={parseInt(existingParams?.page) === 1}
                >
                    Previous
                </Button>
                <Button
                    onClick={handleForwardButtonClick}
                    variant="outlined"
                    style={{ width: '110px', justifyContent: 'flex-end', marginLeft: '4px' }}
                    endIcon={<NavigateNextIcon />}
                    disabled={calibrationData?.length < itemsPerPage || calibrationData?.length === 0 || !calibrationData}
                >
                    Next
                </Button>
            </Grid>
            <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseImageDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'gray',
                            zIndex: 100
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className="slider-container"   style={{overflow: 'hidden', position: 'relative' }}>
                            <Slider {...settings}>
                                {selectedImage?.map((item, idx) => (
                                    <div key={idx} className='slider-component'>
                                        {imageLoading[idx] && (
                                            <CircularProgress
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    width:'40px',
                                                    height: '40px',
                                                    zIndex: '100'
                                                }}
                                            />
                                        )}
                                        <img src={item?.signedUrl}
                                            alt="Attachment"
                                            className='slider-image'
                                            onLoad={() => setImageLoading(prev => ({ ...prev, [idx]: false }))}
                                            onError={() => setImageLoading(prev => ({ ...prev, [idx]: false }))}
                                            onLoadStart={() => setImageLoading(prev => ({ ...prev, [idx]: true }))}
                                        />
                                    </div>
                                )
                                )}
                            </Slider>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            {alertMessage ? (
                <AlertDialog
                    handleClose={() => handleDialogClose()}
                    message={alertMessage}
                    type={msgType}
                    handleDialogClose={() => setAlertMessage(null)}
                />) : null}
        </Grid>

    )
}