
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getFacilityById, getMeterCalibration, getMeterCalibrationById } from '../../Redux/actions';
import moment from 'moment';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { CircularProgress } from '@mui/material';

import BackArrow from '../../Images/BackArrow.png';
// import GreenvironmentLogo from '../../../src/Images/Greenvironment-logo.svg'
import GreenvironmentLogo from '../../../src/Images/Greenvironment-logo-wbg.svg'

export default function CalibrationReport() {
    const { id, client_id, facilityId } = useParams();
    const [calibrationData, setCalibrationData] = useState();
    const [showLoader, setShowLoader] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const selectedItem = location?.state?.selectedItem;
    const [facilityNotFound, setFacilityNotFound] = useState(false);



    useEffect(() => {
        setShowLoader(true);
        dispatch(getMeterCalibrationById(client_id, id)).then(resp => {
            const response = resp?.data?.data
            setCalibrationData(response)
            setShowLoader(false);
        })
        if(facilityId) {
            dispatch(getFacilityById(client_id,facilityId)).then(resp => {
                const response = resp?.data?.facilityData
                if (!response) {
                    setFacilityNotFound(true);
                    return;
                }
            })
        }
    }, [selectedItem])

    const parseAndRenderTable = (dataField) => {
        if (calibrationData) {
            const items = calibrationData[dataField]?.split('<br>');
            return (
                <>
                    {items?.map((item, index) => (
                        <div key={index}>
                            <p>{item ? item : '-'}</p>
                        </div>
                    ))}
                </>
            );
        }
    };

    const handleDownloadReport = () => {
        window.print()
    }


    let calibrationReport;

    if(id && !calibrationData) {
        return <Grid container >
        <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
            <h5 className='w3-text-grey'>
                Calibration report not found
            </h5>
        </Grid>
    </Grid>
    }
    
    if (showLoader) {
        calibrationReport = (<Grid container>
            <Grid item align="center">
                <CircularProgress />
            </Grid>
        </Grid>)
    } else if (!calibrationData) {
        calibrationReport = (<Grid>
            <div style={{ display: 'grid', placeItems: 'center' }}>
                <h6 className="w3-text-grey w3-padding no-data-msg">No Data Found</h6>
            </div>
        </Grid>)
    } else {
        calibrationReport = (<div className="calibration-report-container">
            <Grid style={{ display: 'flex', justifyContent: 'space-between', marginTop:'30px' }}>
                <img id="back-arrow" className="cursor-pointer" width="20px" height="20px" onClick={() => navigate(-1)} src={BackArrow} alt="back-arrow" style={{marginTop: '10px'}} />
                <Button
                    variant="contained"
                    id="download-calibrtaion-report-btn" onClick={() => handleDownloadReport()}>
                    Download Report
                </Button>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                    <img alt="green-logo" width="250px" height="70px" src={GreenvironmentLogo} />
                </Grid>
            </Grid>
            <Grid sx={{ mt: 4, mb: 4 }}>
                <div className='report-header w3-center'>
                    <h3><b>Calibration Report</b></h3>
                </div>
            </Grid>
            <div>
                <div>
                    <div>
                        <h4 className="ct-project-name">{calibrationData?.project?.projname}</h4>
                    </div>
                    <div className='calibration-data'>
                        <p className="ct-sensor-name">{calibrationData?.meter?.metername} Sensor:</p>
                        <TableContainer className="calibartion-table-container" sx={{ mb: 4 }}>
                            <Table>
                                <TableHead className="calibartion-table-head">
                                    <TableRow>
                                        <TableCell className="ct-table-head">Meter No</TableCell>
                                        <TableCell className="ct-table-head">Sensor No</TableCell>
                                        <TableCell className="ct-table-head">Calibrated Date</TableCell>
                                        <TableCell className="ct-table-head">Calibration Due Date</TableCell>
                                        <TableCell className="ct-table-head">Result</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{calibrationData?.meterid}</TableCell>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{calibrationData?.sensor_id}</TableCell>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{moment(calibrationData?.latest_calibration_date).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{moment(calibrationData?.calibration_due_date).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell>{calibrationData?.result?.startsWith('pass') ? 'Pass' : 'Fail'}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer className="calibartion-table-container" sx={{ mb: 5 }}>
                            <Table>
                                <TableHead className="calibartion-table-head">
                                    <TableRow>
                                        <TableCell className="ct-table-head">Tested Characteristics</TableCell>
                                        <TableCell className="ct-table-head">Method of Checking</TableCell>
                                        <TableCell className="ct-table-head">Result</TableCell>
                                        <TableCell className="ct-table-head">Photo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {calibrationData?.dynamicFieldsData?.length > 0 ? (
                                    calibrationData.dynamicFieldsData.map((dynamicField, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            {dynamicField.testedCharacteristics}
                                        </TableCell>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            {dynamicField.methodChecking}
                                        </TableCell>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            {dynamicField.result}
                                        </TableCell>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            {dynamicField.images?.map((image, index) => (
                                                <img
                                                key={index}
                                                style={{ paddingRight: "10px", paddingBottom: "10px" }}
                                                width="120px"
                                                alt={`${idx} pic`}
                                                src={image.signedUrl}
                                                />
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{parseAndRenderTable?.("testedCharacteristics")}</TableCell>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{parseAndRenderTable?.("methodChecking")}</TableCell>
                                        <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }} className="text-capitalize">{parseAndRenderTable?.("result")}</TableCell>
                                        <TableCell><p>No Photos Added</p></TableCell>
                                    </TableRow>
                                )}
                                </TableBody>
                            </Table>
                            </TableContainer>

                    </div>
                    <div>
                        <b>The quality/quantity control test criteria listed above ensure superior performance over the warranted life of the probe when proper storage, calibration and usage instructions are followed.</b>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    if(facilityNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    Calibration report not found
                </h5>
            </Grid>
        </Grid>
    }
    return <Grid container>
        {calibrationReport}
    </Grid>

}